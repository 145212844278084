import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { accountFeatureActions } from '../../state/account/actions/account-feature.actions';

@Injectable()
export class SsoApiInterceptor implements HttpInterceptor {
  private store = inject(Store);

  intercept<RequestBody>(req: HttpRequest<RequestBody>, next: HttpHandler): Observable<HttpEvent<RequestBody>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !req.url.endsWith('/account/me') && !req.url.endsWith('/sign-in-status')) {
          this.store.dispatch(accountFeatureActions.logout());
        }
        return throwError(() => error);
      }),
    );
  }
}
