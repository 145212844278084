import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { combineLatest, map } from 'rxjs';

import { TranslocoService } from '@jsverse/transloco';

import { AppBrandingService } from '@ciphr/core/app-branding';

import { toObservable } from '@angular/core/rxjs-interop';

const CSI_APP_FALLBACK_NAME = 'CSI';

export const pageTitleResolverFactory =
  (pageTitleTranslationKey: string): ResolveFn<string> =>
  () => {
    const brandingService = inject(AppBrandingService);
    const translocoService = inject(TranslocoService);

    const productName$ = toObservable(brandingService.productName);

    return combineLatest([translocoService.selectTranslate(pageTitleTranslationKey), productName$]).pipe(
      map(([pageTitle, productName]) => `${pageTitle} - ${productName || CSI_APP_FALLBACK_NAME}`),
    );
  };
