import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { accountState, selectUserName } from './state/account/account.selectors';
import { EMAIL_REGEXP } from './core/email.regexp';
import { DOCUMENT } from '@angular/common';

export const loginGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  const document = inject(DOCUMENT);
  const window = document.defaultView;

  return store.select(accountState).pipe(
    map(({ userName, defaultAppUrl }) => {
      if (userName !== '') {
        if (window) {
          window.location.href = window.location.origin + defaultAppUrl;
          return false;
        } else {
          return router.createUrlTree(['profile']);
        }
      }
      return true;
    }),
  );
};

export const profileGuard: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectUserName).pipe(map((userName) => (userName !== '' ? true : router.createUrlTree(['login']))));
};

export const newPasswordGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);

  return route.queryParamMap.get('userId') && route.queryParamMap.get('token') ? true : router.createUrlTree(['login']);
};

export const basicLoginGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);

  const userNameQueryParam = route.queryParamMap.get('userName');
  const verifyTfa = !!route.queryParamMap.get('verifytfa');
  
  if (verifyTfa) {
    return true;
  }

  if (!userNameQueryParam || !EMAIL_REGEXP.test(userNameQueryParam)) {
    return router.createUrlTree(['login']);
  }

  return true;
};
