import { Routes } from '@angular/router';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { resetPasswordFeature } from './reset-password/state/reset-password.reducer';
import { ResetPasswordEffects } from './reset-password/state/reset-password.effects';
import { newPasswordFeature } from './new-password/state/new-password.reducer';
import { NewPasswordEffects } from './new-password/state/new-password.effects';
import { basicLoginGuard, loginGuard, newPasswordGuard, profileGuard } from './auth.guard';
import { adminGuard } from './admin/admin.guard';
import { PageNotFoundComponent } from '@ciphr/shared/page-not-found';
import { loginFeature } from './login/state/login.reducer';
import { LoginEffects } from './login/state/login.effects';
import { pageTitleResolverFactory } from './shared/page-title/page-title.resolver';

export const appRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./login/single-sign-on-login/single-sign-on-login.component'),
    providers: [provideState(loginFeature), provideEffects([LoginEffects])],
    canActivate: [loginGuard],
    title: pageTitleResolverFactory('PAGE.LOGIN.TITLE'),
  },
  {
    path: 'basic-login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [loginGuard, basicLoginGuard],
    title: pageTitleResolverFactory('PAGE.LOGIN.TITLE'),
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./reset-password/reset-password.component').then((c) => c.ResetPasswordComponent),
    providers: [provideState(resetPasswordFeature), provideEffects([ResetPasswordEffects])],
    title: pageTitleResolverFactory('PAGE.RESET_PASSWORD.TITLE'),
  },
  {
    path: 'new-password',
    loadComponent: () => import('./new-password/new-password.component').then((c) => c.NewPasswordComponent),
    providers: [provideState(newPasswordFeature), provideEffects([NewPasswordEffects])],
    canActivate: [newPasswordGuard],
    title: pageTitleResolverFactory('PAGE.NEW_PASSWORD.TITLE'),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canMatch: [profileGuard],
    title: pageTitleResolverFactory('PAGE.PROFILE.TITLE'),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canMatch: [adminGuard],
    title: pageTitleResolverFactory('PAGE.ADMIN.TITLE'),
  },
  {
    path: 'error',
    loadComponent: () => import('./error/error.component').then((c) => c.ErrorComponent),
  },
  {
    path: 'logout',
    loadComponent: () => import('./logout/logout.component').then((c) => c.LogoutComponent),
    canActivate: [loginGuard],
    title: pageTitleResolverFactory('PAGE.LOGOUT.TITLE'),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', component: PageNotFoundComponent },
];
